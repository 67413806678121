export class ResourcesModel {
  public id?: number;
  public name?: string;
  public resourceDescription?: string;
  public photoUrl?: string;
  // public photoHeight: number;
  // public photoWidth: number;
  public resourceUrl?: string;
  public photoAltText?: string;
  constructor() {}
}
